import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Brandon Shumba | portfolio', // 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my site', 
};

// HERO DATA
export const heroData = {
  title: '',
  name: '',
  subtitle: '',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  resume: 'https://drive.google.com/file/d/1y6vwNNK0tA2ps9eX2mCoOpOxVZdXtTHd/view', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'app.jpg',
    title: 'Android and VB.NET apps',
    info: 'I created an android app that allows you to see deals from various travel agents. It uses firebase database and Google authentication.',
    info2: 'I also created a club management system using Visual basic.NET. Below are links to my github containing their code.',
    url: 'https://github.com/BranTkS',
    repo: 'https://github.com/BranTkS/travel-deals-app', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'websites.jpg',
    title: 'Full Stack website development',
    info: 'I created many websites over the years using react, WordPress and JavaScript. I freelanced several websites including a website for a gym, a construction company, a restaurant and my biggest project to date was a news site with multiple writers, subscriptions and sections.',
    info2: '',
    url: 'https://github.com/BranTkS',
    repo: 'https://github.com/BranTkS/Excercise-logger', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'graphics.jpg',
    title: 'Graphic design',
    info: "I design advertising, logos, banners, and fliers as well as video editing for clients. I've learned to use many tools, applications and softwares to get the job done. Check the links below to see more Project samples.",
    info2: '',
    url: 'https://www.deviantart.com/brantshumba',
    repo: 'https://www.deviantart.com/brantshumba', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: '',
};

// FOOTER DATA
export const footerData = {
  networks: [
/*{
      id: nanoid(),
      name: 'twitter',
      url: '',
    },*/
    {
      id: nanoid(),
      name: 'deviantart',
      url: 'https://www.deviantart.com/brantshumba',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://linkedin.com/in/brandon-shumba',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/BranTkS',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
